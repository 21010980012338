<template>
  <div>
    <nav-bar headerTitle="运动方案" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container col-start-center">

      <div class="evolution-box row-between-center">
        <div @click="goAccess(item)" class="evolution row-between-center" v-for="item in 2" :key="item">
          <p class="f28 row-start-center">
            <van-icon :name="`https://img.chaolu.com.cn/ACT/talk-order/icon${item === 2 ? '' : '1'}.png`" />
            <span class="p1">{{ item === 1 ? '日常情况评估' : '风险评估' }}</span>
          </p>
          <van-icon class="arrow" name="arrow" />
        </div>
      </div>

      <div v-if="curCase" @click="goCase(curCase)" class="case-item">
        <div class="case-info">
          <div class="name f30 fw6 ellipsis">{{ curCase.months }}个月 · 每周{{ curCase.weekCount }}次</div>
          <div class="labels row-start-center flex-wrap">
            <div class="label red f24 row-center-center" v-for="label in curCase.labelList" :key="label">{{ label }}</div>
          </div>
          <div class="teacher row-start-center">
            <img class="head" :src="curCase.createTeacherPic" alt="">
            <p class="f22">{{ curCase.createTeacherName }} 创建于{{ curCase.startTime }}(方案时效{{ curCase.useDays }}天)</p>
          </div>
        </div>
      </div>

      <p v-if="history" class="line f22">—— 以下为历史方案报告 ——</p>

      <van-list v-model="loading" :finished="finished" @load="getList" :offset="50" :immediate-check="false"	finished-text="">
        <div @click="goCase(item)" class="case-item" v-for="item in history" :key="item.userTeachingTemplateId">
          <div class="case-info">
            <div class="name f30 fw6 ellipsis">{{ item.months }}个月 · 每周{{ item.weekCount }}次</div>
            <div class="labels row-start-center flex-wrap">
              <div class="label f24 row-center-center" v-for="label in item.labelList" :key="label">{{ label }}</div>
            </div>
            <div class="teacher row-start-center">
              <img class="head" :src="item.createTeacherPic" alt="">
              <p class="f22">{{ item.createTeacherName }} 创建于{{ item.startTime }}(方案时效{{ item.useDays }}天)</p>
            </div>
          </div>
        </div>
      </van-list>

      <div v-if="!curCase && !history" class="empty col-center-center">
        <img src="https://img.chaolu.com.cn/ACT/mini-records/empty.png" alt="">
        <p class="f28">暂无数据<br>可以去门店找教练定制哦</p>
      </div>
    </div>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import wx from 'weixin-js-sdk'


export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      curCase: '', // 当前方案
      history: [], // 历史

      current: 1,
      loading: false,
      finished: false
    }
  },

  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()

    this.getList()
  },

  methods: {
    newAppBack,

    getList() {
      this.loading = true
      this.$axios.post(`${this.baseURLApp}/userApp/user/teaching/template/listUserHistoryTemplate`, {
        userId: this.userId,
        token: this.token,
        current: this.current,
        size: 10
      }).then(res => {
        if (!res.data) {
          this.curCase = null
          this.history = null
        }

        this.curCase = res.data.current
        this.history = this.history.concat(res.data.history)

        this.loading = false
        this.current++
        if (res.data.history < 10) {
          this.finished = true
        }
      }).catch(r => {
        this.finished = true
        this.loading = false
      })
    },

    goAccess(type) {
      // 1风险 2日常
      const t = type === 1 ? 2 : 1
      appOpenWeb('',  `${window.location.origin}/#/talk-order/questionnaire?applicationTrends=${t}`)
    },
    goCase(item) {
      appOpenWeb('',  `${window.location.origin}/#/talk-order/case?userTeachingTemplateId=${item.userTeachingTemplateId}`)
    }
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.container {
  background-color: #f5f5f5;
  box-sizing: border-box;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  width: 100%;
  padding: 24px 32px calc(24px + env(safe-area-inset-bottom));
  .evolution-box {
    width: 100%;
    .evolution {
      width: 333px;
      height: 90px;
      background: #FFFFFF;
      border-radius: 12px;
      padding: 0 32px;
      margin: 0 0 24px;
      .p1 {
        margin: 0 0 0 18px;
      }
      .van-icon {
        font-size: 32px;
        margin: 4px 0 0;
      }
      .arrow {
        font-size: 24px;
      }
    }
  }

  .line {
    color: #6C727A;
    margin: 30px 0 28px;
  }

  .van-list {
    width: 100%;
  }
  .case-item {
    width: 100%;
    padding: 26px;
    background: #fff;
    display: flex;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    margin: 0 0 20px;

    &:last-of-type {
      margin: 0;
    }



    .case-info {
      position: relative;
      overflow: hidden;
      flex: 1;

      .name {
        color: #242831;
      }

      .teacher {
        margin-top: 16px;

        .head {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 11px 0 0;
        }
        p {
          color: #6C727A;
        }
      }
    }

    .time {
      color: #242831;
      margin-top: 8px;
    }

    .labels {
      white-space: nowrap;
      max-height: 105px;
      overflow: hidden;
      margin-top: 4px;
      .label {
        margin-top: 8px;
        padding: 0 10px;
        height: 44px;
        background: #F5F6FA;
        border-radius: 4px;
        margin-right: 10px;
        line-height: 30px;
        max-width: 100%;
        overflow: hidden;
        &.red {
          background: rgba(252, 44, 17, .1);
          color: #FC2C11;
        }
      }
    }

    .price-line {
      margin-top: 8px;
      line-height: 1;

      .dl {
        color: #EE4832;
        margin-right: 4px;
      }

      .price {
        color: #EE4832;
        margin-right: 9px;
        position: relative;
        top: 2px;
      }

      .ori-price {
        text-decoration: line-through;
        color: #6C727A;
      }
    }

    .send {
      position: absolute;
      left: 0;
      top: 0;
      border-bottom-right-radius: 16px;
      background: linear-gradient(90deg, #3889F0 0%, #A26BDC 99%);
      padding: 0 18px;
      line-height: 38px;
      color: #fff;
    }
  }
  .empty {
    text-align: center;
    margin: 240px 0 0;
    img {
      width: 300px;
    }
    p {
      color: #6C727A;
      margin: 24px 0 0;
      line-height: 44px;
    }
  }



}
</style>
