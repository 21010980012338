<template>
  <div v-if="showPage">
    <nav-bar headerTitle="运动方案" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container col-start-center">
      <!-- 用户信息 -->
      <div class="user-box">
        <div class="row-start-center">
          <img :src="detail.user.headImg" alt="" class="head" />
          <div>
            <p class="f32 fw6">{{ detail.user.nickName }}</p>
            <p class="p2 f24">{{ detail.startTime }} 由{{ detail.createTeacherName }}教练创建</p>
          </div>
        </div>
        <div class="user-plan f24 flex flex-wrap">
          <div class="plan-item">
            计划时长：<span class="fw6">{{ detail.months }}个月</span>
          </div>
          <div class="plan-item">
            每周频率：<span class="fw6">每周{{ detail.weekCount }}次</span>
          </div>
        </div>
      </div>
      <!-- 数据 -->
      <div class="block-item" v-if="detail.bodyShape">
        <h2 class="title f32 fw6">{{ userId_ ? '' : '您的' }}体型</h2>
        <div class="block">
          <div class="common-box">
            <div class="common-item">
              <div class="tag red row-center-center fw6">{{ detail.bodyShape.name }}</div>
              <p class="f24">{{ detail.bodyShape.suggest }}</p>
              <img v-if="detail.bodyShape.image" @click="clickImage(0, [detail.bodyShape.image])" class="img" :src="detail.bodyShape.image" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="block-item" v-if="detail.bodyAnalysisList">
        <h2 class="title f32 fw6">{{ userId_ ? '' : '您的' }}体测数据</h2>
        <div class="block">
          <div class="table-box">
            <div class="table-item row-start-center f24" v-for="item in detail.bodyAnalysisList" :key="item.name">
              <span class="s1">{{ item.name }}</span>
              <span class="s2">{{ item.value }}{{ item.unit }}</span>
              <span :class="['s3', item.grade === 2 ? '' : 'color-red', 'flex-auto']">{{ bodyAnalysisMap[item.grade] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block-item" v-if="detail.postureList">
        <h2 class="title f32 fw6">体态问题</h2>
        <div class="block">
          <div class="common-box">
            <div class="common-item" v-for="item in detail.postureList" :key="item.postureId">
              <div class="tag row-center-center fw6">{{ item.postureName }}</div>
              <div v-for="(v, k) in item.dataReportList" :key="k">
                <div v-if="v.type === 'title'" class="item-title">{{ v.content }}</div>
                <p v-if="v.type === 'paragraph'" class="f24">{{ v.content }}</p>
                <div v-if="v.type === 'video'" class="video-box">
                  <video
                    :id="item.postureId + '' + k"
                    @click="pause(v, item.postureId, k)"
                    preload="auto"
                    poster="https://img.chaolu.com.cn/ACT/talk-order/video_bg.png"
                    x5-video-orientation="portraint"
                    x-webkit-airplay="true"
                    x5-video-player-type="h5-page"
                    x5-video-player-fullscreen="true"
                    style="object-fit: cover; width: 100%; height: 100%"
                    :src="v.content"></video>
                  <div v-show="!v.isPlay" class="bg row-center-center">
                    <img @click="play(v, item.postureId, k)" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="" />
                  </div>
                </div>
                <img v-if="v.type === 'image'" @click="clickImage(0, [v.content])" class="img" :src="v.content" alt="" />
                <div v-if="v.type === 'images'" class="row-start-center sudoku-img">
                  <img v-for="(img, index) in v.content.split(',')" @click="clickImage(index, v.content.split(','))" :key="img" :src="img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block-item" v-if="detail.girthList">
        <h2 class="title f32 fw6">身体围度</h2>
        <div class="block">
          <div class="size-box flex flex-wrap">
            <div class="size-item f22" v-for="item in detail.girthList" :key="item.girthName">
              <p class="s1">{{ item.girthName }}</p>
              <p class="">
                <span class="s2 BebasNeueBold f48">{{ item.value }}</span>
                <span class="s3 red flex-auto">{{ item.unit }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="block-item" v-if="detail.athleticismList">
        <h2 class="title f32 fw6">体能信息</h2>
        <div class="block">
          <div class="table-box">
            <div class="table-item row-start-center f24" v-for="item in detail.athleticismList" :key="item.name">
              <span class="s1">{{ item.athleticismName }}</span>
              <span class="s2">{{ item.testData }}{{ item.unit }}</span>
              <span :class="['s3', item.grade === 2 ? '' : 'color-red', 'flex-auto']">{{ item.judge }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="block-item" v-if="detail.userTarget">
        <h2 class="title f32 fw6">会员目标</h2>
        <div class="block">
          <h3 class="f28">目标周期</h3>
          <div class="target-box row-between-center">
            <p class="f32 fw6 color-blue">{{ detail.userTarget.cycleNum }}周</p>
            <p class="f20">
              教练与您一起尽力在 <span class="f36 color-blue BebasNeueBold">{{ detail.userTarget.achieveTime }}</span> 前完成
            </p>
          </div>

          <template v-for="target in userTargetMap">
            <div v-if="detail.userTarget[target.key] && detail.userTarget[target.key].length" :key="target.name" class="target-table">
              <h3 class="f28">{{ target.name }}</h3>
              <div class="table-box border f26 mt">
                <div class="table-head row-start-center fw6">
                  <span class="s4 flex-auto">类型</span>
                  <span class="s5">当前</span>
                  <span class="s6">目标</span>
                </div>
                <div class="table-item high row-start-center" v-for="item in detail.userTarget[target.key]" :key="item.type">
                  <span class="s4 flex-auto">{{ item.field }}（{{ item.unit }}）</span>
                  <span class="s5">{{ item.currentValue }}</span>
                  <span class="s6 color-blue">{{ item.targetValue }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="block-item" v-if="detail.riskAssessment">
        <h2 class="title f32 fw6">运动风险评估</h2>
        <div class="block">
          <div class="common-box">
            <div class="common-item">
              <div class="tag row-center-center fw6">{{ detail.riskAssessment.riskName }}</div>
              <p class="f24">{{ detail.riskAssessment.suggest }}</p>
            </div>
          </div>
        </div>
      </div>

      <!--不良-->
      <div class="block-item" v-if="detail.problemSymptomRecordList">
        <h2 class="title f32 fw6">运动需求</h2>
        <div class="block">
          <div class="common-box">
            <div class="common-item" v-for="item in detail.problemSymptomRecordList" :key="item.problemSymptomSubId">
              <div class="tag row-center-center fw6">{{ item.problemSymptomSubName }}</div>
              <div v-for="(v, k) in item.dataReportList" :key="k">
                <div v-if="v.type === 'title'" class="item-title">{{ v.content }}</div>
                <p v-if="v.type === 'paragraph'" class="f24">{{ v.content }}</p>
                <div v-if="v.type === 'video'" class="video-box">
                  <video
                    :id="item.postureId + '' + k"
                    @click="pause(v, item.postureId, k)"
                    preload="auto"
                    poster="https://img.chaolu.com.cn/ACT/talk-order/video_bg.png"
                    x5-video-orientation="portraint"
                    x-webkit-airplay="true"
                    x5-video-player-type="h5-page"
                    x5-video-player-fullscreen="true"
                    style="object-fit: cover; width: 100%; height: 100%"
                    :src="v.content">
                  </video>
                  <div v-show="!v.isPlay" class="bg row-center-center">
                    <img @click="play(v, item.postureId, k)" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="" />
                  </div>
                </div>
                <img v-if="v.type === 'image'" @click="clickImage(0, [v.content])" class="img" :src="v.content" alt="" />
                <div v-if="v.type === 'images'" class="row-start-center sudoku-img">
                  <img v-for="(img, index) in v.content.split(',')" @click="clickImage(index, v.content.split(','))" :key="img" :src="img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block-item" v-if="detail.templateInfoList && detail.templateInfoList.length">
        <h2 class="title f32 fw6">课程计划</h2>
        <div class="block mt">
          <div class="progress-box">
            <div class="progress-item">
              <div class="flex flex-wrap">
                <div class="course-list">
                  <div @click="goTrainingPlan(item)" class="course-item row-between-center" v-for="(item, index) in detail.templateInfoList" :key="item.templateId">
                    <div class="c-poster flex-none" :style="`background-image: url(${item.backgroundImage})`"></div>
                    <div class="c-content flex-auto">
                      <p class="f30 fw6">{{ item.templateName }}</p>
                      <div class="line-1 f20 flex">
                        <p class="p1">
                          <span class="fw6 f24">{{ item.motionNum }}</span>
                          <span class="opacity9"> 动作</span>
                        </p>
                        <p class="p1">
                          <span class="fw6 f24">{{ item.groupNum }}</span>
                          <span class="opacity9"> 组</span>
                        </p>
                        <p class="p1">
                          <span class="fw6 f24">{{ item.sumKg }}</span>
                          <span class="opacity9"> kg</span>
                        </p>
                      </div>
                      <div class="f22 opacity9 flex">
                        <p class="p2 ellipsis">{{ item.labelStr }}</p>
                      </div>
                    </div>
                    <van-icon v-if="!userId_" name="arrow" size="14" color="#999" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!userId_" class="button-box f32">
      <div @click="goBuy" :class="['button', 'row-center-center', 'fw6']">购买最新优惠卡券</div>
    </div>
    <!-- <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false" :scale="scale" @share-success="isShareShow = false" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss" v-if="detail.user.userId">
          <img src="https://img.chaolu.com.cn/ACT/talk-order/share/headbg.png" class="headbg" />
          <div class="con">
            <div class="basediv headline">
              <div class="userhead">
                <img :src="detail.user.headImg" />
              </div>
              <div class="dc">
                <div class="username fw6">{{ detail.user.nickName }}</div>
                <div>{{ detail.startTime }} 由{{ detail.createTeacherName }}教练创建</div>
              </div>
              <div class="dr">
                <div class="mb">
                  计划时长：<span class="fw6">{{ detail.months }}个月</span>
                </div>
                <div>
                  每周频率：<span class="fw6">每周{{ detail.weekCount }}次</span>
                </div>
              </div>
            </div>
            <div class="basediv sec-tx" v-if="detail.bodyShape">
              <div class="basetitle">{{ userId_ ? '' : '您的' }}体型</div>
              <div class="color-red big mg">{{ detail.bodyShape.name }}</div>
              <div class="jy">{{ detail.bodyShape.suggest }}</div>
            </div>
            <div class="pub_avg testline">
              <div class="basediv hmodel">
                <div class="basetitle">体测数据</div>
                <div class="item-list" v-if="detail.bodyAnalysisList && detail.bodyAnalysisList.length">
                  <div class="item-model" :class="{ 'color-red': item.grade !== 2 }" v-for="item in detail.bodyAnalysisList">
                    <div>
                      <span class="value BebasNeueBold">{{ item.value }}</span>{{ item.unit }}
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
                </div>
                <div v-else class="nodata">
                  <img src="https://img.chaolu.com.cn/ACT/talk-order/share/null.png" />
                  <div class="font">暂无数据</div>
                </div>
              </div>
              <div class="basediv hmodel">
                <div class="basetitle">体能信息</div>
                <div class="item-list" v-if="detail.athleticismList && detail.athleticismList.length">
                  <div class="item-model" :class="{ 'color-red': item.grade !== 2 }" v-for="item in detail.athleticismList">
                    <div>
                      <span class="value BebasNeueBold">{{ item.testData }}</span>
                      {{ item.unit }}
                    </div>
                    <div>{{ item.athleticismName }}</div>
                  </div>
                </div>
                <div v-else class="nodata">
                  <img src="https://img.chaolu.com.cn/ACT/talk-order/share/null.png" />
                  <div class="font">暂无数据</div>
                </div>
              </div>
              <div class="basediv hmodel">
                <div class="basetitle">身体围度</div>
                <div class="item-list" v-if="detail.girthList && detail.girthList.length">
                  <div class="item-model" v-for="item in detail.girthList">
                    <div>
                      <span class="value BebasNeueBold">{{ item.value }}</span>
                      {{ item.unit }}
                    </div>
                    <div>{{ item.girthName }}</div>
                  </div>
                </div>
                <div v-else class="nodata">
                  <img src="https://img.chaolu.com.cn/ACT/talk-order/share/null.png" />
                  <div class="font">暂无数据</div>
                </div>
              </div>
            </div>
            <div class="basediv sec-tt" v-if="detail.postureList">
              <div class="basetitle">体态问题</div>
              <template v-for="item in detail.postureList">
                <div class="color-red big mg">{{ item.postureName }}</div>
                <div v-for="citem in item.dataReportList" class="jy" v-if="citem.type == 'paragraph'">{{ citem.content }}</div>
              </template>
            </div>
            <div class="basediv sec-tt" v-if="detail.riskAssessment">
              <div class="basetitle">运动风险评估</div>
              <div class="color-red big mg">{{ detail.riskAssessment.riskName }}</div>
              <div class="jy">{{ detail.riskAssessment.suggest }}</div>
            </div>
            <div class="basediv sec-tt" v-if="detail.problemSymptomRecordList">
              <div class="basetitle">运动需求</div>
              <template v-for="item in detail.problemSymptomRecordList">
                <div class="color-red big mg">{{ item.problemSymptomSubName }}</div>
                <div v-for="citem in item.dataReportList" class="jy" v-if="citem.type == 'paragraph'">{{ citem.content }}</div>
              </template>
            </div>
            <div class="basediv sec-plan" v-if="detail.templateInfoList">
              <div class="basetitle mgb">课程计划</div>
              <div class="course-list">
                <div class="course-item row-between-center" v-for="(item, index) in detail.templateInfoList" :key="item.templateId">
                  <div class="c-poster flex-none" :style="`background-image: url(${item.backgroundImage})`">

                  </div>
                  <div class="c-content flex-auto">
                    <p class="f30 fw6">{{ item.templateName }}</p>
                    <div class="line-1 f20 flex">
                      <p class="p1">
                        <span class="fw6 f24">{{ item.motionNum }}</span>
                        <span class="opacity9"> 动作</span>
                      </p>
                      <p class="p1">
                        <span class="fw6 f24">{{ item.groupNum }}</span>
                        <span class="opacity9"> 组</span>
                      </p>
                      <p class="p1">
                        <span class="fw6 f24">{{ item.sumKg }}</span>
                        <span class="opacity9"> kg</span>
                      </p>
                    </div>
                    <div class="f22 opacity9 flex">
                      <p class="p2 ellipsis">{{ item.labelStr }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bline">
              <div class="bl">
                <div class="bc">
                  <div class="t1">扫码查看</div>
                  <div class="t2">您的运动方案详情</div>
                </div>
                <img src="https://img.chaolu.com.cn/ACT/talk-order/share/logo.png" class="logo" />
              </div>
              <img :src="userPoster" class="ucode" />
            </div>
          </div>
        </div>
      </template>
    </common-share> -->
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { initBack, newAppBack, appOpenWeb } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import wx from 'weixin-js-sdk'
import { ImagePreview } from 'vant'
import commonShare from '@/components/commonShare'
const pageUrl = `${window.location.origin}/#/talk-order/case`
const shareUrl = `pages/webView/index?webUrl=${pageUrl}`

export default {
  mixins: [userMixin],
  components: {
    navBar,
    commonShare,
  },
  data() {
    return {
      detail: { user: {} },
      mall: [], // 商城列表第一项
      isPlay: false,
      bodyAnalysisMap: {
        1: '偏低',
        2: '正常',
        3: '偏高',
      },
      shareParams: {
        title: '运动报告！',
        content: '运动报告！',
        multiple: '1',
        userName: 'gh_7f5b57b6a76e',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        miniImage: 'https://img.chaolu.com.cn/ACT/new-people-2022/new/mini2.png',
        path: shareUrl,
      },
      isShareShow: false,
      shareItem: ['posterfriend', 'postermessage', 'save'],
      userPoster: '',
      showPage: true,

      userTargetMap: [
        { key: 'physicalTest', name: '体测目标' },
        { key: 'circumference', name: '围度目标' },
        { key: 'athleticAbility', name: '运动能力目标' },
      ],
    }
  },

  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()
    const userTeachingTemplateId = getParam().userTeachingTemplateId
    this.userTeachingTemplateId = userTeachingTemplateId
    this.shareParams.path = `${shareUrl}&userTeachingTemplateId=${userTeachingTemplateId}`
    this.userId_ = getParam().userId
    this.getDetail()
    // this.initQr()
  },

  methods: {
    newAppBack,
    scale(cvs) {
      return 1.7 - (0.25 - cvs.offsetWidth / cvs.offsetHeight) * 6
    },
    // initQr() {
    //   const ps = {
    //     source: 'talk-order-case',
    //     webUrl: pageUrl,
    //     userId: 1,
    //     userTeachingTemplateId: this.userTeachingTemplateId,
    //   }
    //   console.log(ps)
    //   return this.$axios
    //     .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
    //       hasWx: true,
    //       userId: this.userId,
    //       token: this.token,
    //       width: 240,
    //       height: 240,
    //       webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
    //       scene: JSON.stringify(ps),
    //     })
    //     .then((res) => {
    //       this.userPoster = 'data:image/png;base64,' + res.data.qrCode
    //     })
    // },
    showPoster() {
      if (this.detail.user.userId) {
        this.isShareShow = true
      } else {
        this.$toast('请稍后再试')
      }
    },
    getDetail() {
      this.$axios
        .post(`${this.baseURLApp}/userApp/user/teaching/template/info`, {
          userId: this.userId_ || this.userId,
          userTeachingTemplateId: this.userTeachingTemplateId,
        })
        .then((res) => {
          this.detail = res.data || { user: {} }
          if (!this.userId_) this.getMall()

        })
        .catch((r) => {
          this.showPage = false
          setTimeout(() => {
            if (this.appTypeStr == 'mini') {
              wx.miniProgram.switchTab({ url: '/pages/home/index' })
            } else {
              this.newAppBack()
            }
          }, 3000)
        })
    },

    getMall() {
      this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/querySportsPlanningShop`, {
          userId: this.userId,
          teacherId: this.detail.createTeacherId,
        })
        .then((res) => {
          this.mall = res.data[0]
        })
        .catch((r) => {})
    },
    goBuy() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/marketing?id=${this.mall.id}&inviteTeacherId=${this.detail.createTeacherId}&from=teaching_template`)
    },
    goTrainingPlan(item) {
      if (this.userId_) return
      appOpenWeb(item.templateName, `${window.location.origin}/#/training-record/template?templateId=${item.templateId}`)
    },
    play(item, postureId, index) {
      let video = document.getElementById(`${postureId}${index}`)
      video.play()
      this.$set(item, 'isPlay', true)
    },
    pause(item, postureId, index) {
      let video = document.getElementById(`${postureId}${index}`)
      video.pause()
      this.$set(item, 'isPlay', false)
    },
    clickImage(index, list) {
      ImagePreview({
        images: list,
        startPosition: index,
        loop: false,
      })
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.button-box {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  color: #242831;
  position: sticky;
  bottom: 0;
  padding: 20px 32px calc(20px + env(safe-area-inset-bottom));
  border-top: 1px solid #ebebeb;
  position: sticky;
  bottom: 0;
  left: 0;

  .button {
    width: 100%;
    height: 96px;
    background: #ffde00;
    border-radius: 8px;
  }
}

.container {
  background-color: #f5f5f5;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-height: 90vh;
  padding: 24px 32px calc(24px + env(safe-area-inset-bottom));

  .user-box {
    width: 100%;
    padding: 32px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0 0 40px;

    .head {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      margin: 0 30px 0 0;
    }

    .p2 {
      color: #6c727a;
      margin: 8px 0 0;
    }

    .user-plan {
      margin: 36px 0 0;
      padding: 6px 0 0;
      border-top: 2px solid #eeeeee;

      .plan-item {
        width: 50%;
        margin: 22px 0 0;
      }
    }
  }

  .block-item {
    width: 100%;
    margin: 0 0 40px;

    .title {
      line-height: 1;
      padding: 0 0 0 18px;
      border-left: 10px solid #ffde00;
      margin: 0 0 28px;
    }

    .block {
      padding: 32px;
      border-radius: 20px;
      background-color: #fff;

      &.mt {
        margin-bottom: 24px;
      }
    }
  }
  .target-box {
    width: 100%;
    height: 80px;
    margin: 28px 0 0;
    padding: 0 30px;
    background: #f2f7ff;
    border-radius: 12px;
  }

  .common-box {
    .common-item {
      &:not(:first-child) {
        margin: 40px 0 0;
      }
      .tag {
        margin: 0 0 30px;
        color: #f01818;
        background: url('https://img.chaolu.com.cn/ACT/talk-order/tag.png') left center no-repeat;
        background-size: 35px 20px;
        justify-content: flex-start;
        padding-left: 48px;
        line-height: 30px;
      }

      .item-title {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 28px;
      }

      p {
        line-height: 44px;
        &:not(:last-child) {
          margin: 0 0 28px;
        }
      }

      .video-box {
        width: 622px;
        height: 350px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        margin-bottom: 28px;

        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);

          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .img {
        width: 622px;
        display: block;
        margin-bottom: 28px;
        border-radius: 12px;
      }

      .sudoku-img {
        margin-bottom: 28px;
        flex-wrap: wrap;

        img {
          width: 200px;
          height: 200px;
          margin-right: 10px;
          object-fit: cover;
          border-radius: 6px;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .target-table {
    margin: 36px 0 0;
    .mt {
      margin: 24px 0 0;
    }
  }
  .table-box {
    border-radius: 12px;
    overflow: hidden;
    &.border {
      border: 1px solid #EEEEEE;
    }
    .table-head {
      background: #f2f7ff;
      height: 88px;
    }
    .table-item {
      height: 68px;
      &.high {
        height: 88px;
      }
      .s1 {
        width: 264px;
        padding: 0 0 0 40px;
      }
      .s2 {
        width: 270px;
      }
    }
    .table-item,
    .table-head {
      .s4 {
        padding: 0 0 0 40px;
      }
      .s5,
      .s6 {
        width: 124px;
      }
    }

    .table-item:nth-child(odd) {
      background-color: #f9fafb;
    }
  }

  .size-box {
    .size-item {
      width: 33.33%;
      padding: 24px 0 24px 28px;

      .s1 {
        margin: 0 0 14px;
      }

      .s2 {
        margin: 0 8px 0 0;
      }
    }
  }

  .progress-box {
    .progress-item {
      .p-title {
        margin: 0 0 16px;
      }

      .p2 {
        line-height: 44px;
      }

      .p-bar {
        width: 622px;
        padding: 0 22px;
        min-height: 80px;
        background: #f5f5f5;
        border-radius: 8px;

        & + .p-bar {
          margin-top: 20px;
        }

        p {
          margin: 0 0 0 22px;
        }

        .van-icon {
          font-size: 36px;
          font-weight: 600;
          margin: 6px 0 0;
        }
      }
    }
  }
}

@bei: 1;

.canvascss {
  * {
    box-sizing: border-box;
  }

  position: fixed;
  left: 200%;
  top: 0;
  // bottom: 0;
  width: @bei*1013px;
  margin: 0 auto;
  box-sizing: border-box;
  background: #f5f5f5;
  z-index: 99999;
  padding: @bei*110px 0 @bei*42px;

  .con {
    padding: 0 @bei*24px;
  }

  .headbg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .basediv {
    background: #fff;
    border-radius: @bei*10px;
    margin: 0 0 @bei*18px;
    padding: @bei*20px @bei*24px;
    position: relative;
    z-index: 1;
  }

  .basetitle {
    background: #ffea5e;
    border-radius: @bei*6px;
    height: @bei*44px;
    line-height: @bei*44px;
    min-width: @bei*124px;
    text-align: center;
    padding: 0 @bei*18px;
    color: #000;
    font-weight: bold;
    font-size: @bei*22px;
    width: fit-content;
  }

  .big {
    font-size: @bei*28px;
    font-weight: bold;
    margin: @bei*25px 0 @bei*10px;
  }

  .jy {
    color: #000;
    font-size: @bei*20px;
    line-height: @bei*34px;
  }

  .headline {
    display: flex;
    align-items: center;
    font-size: @bei*22px;
    color: #000;
    min-height: @bei*164px;
    padding-left: @bei*32px;
    padding-right: @bei*32px;

    .userhead {
      width: @bei*100px;
      height: @bei*100px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dc {
      flex: 1;
      flex-shrink: 0;
      margin: 0 @bei*26px;

      .username {
        font-size: @bei*32px;
        margin-bottom: @bei*10px;
      }
    }

    .dr {
      text-align: right;

      .mb {
        margin-bottom: @bei*25px;
      }
    }
  }

  .testline {
    .hmodel {
      width: @bei*311px;
      min-height: @bei*450px;
      .item-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: @bei*27px;
        margin-left: @bei*-24px;
        margin-right: @bei*-24px;

        .item-model {
          width: 50%;
          font-size: @bei*20px;
          margin-top: @bei*28px;

          .value {
            font-size: @bei*38px;
            margin-right: @bei*7px;
          }
        }
      }
      .nodata {
        text-align: center;
        padding-top: @bei*45px;
        img {
          width: @bei*200px;
          height: @bei*200px;
          margin-bottom: @bei*12px;
        }
        .font {
          font-size: @bei*20px;
          color: #6c727a;
        }
      }
    }
  }

  .sec-plan {
    .mgb {
      margin-bottom: @bei*18px;
    }
  }

  .bline {
    padding: 0 @bei*8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: @bei*32px;

    .bl {
      text-align: right;
      margin-right: @bei*35px;
      margin-left: @bei*-60px;
      width: @bei*200px;
    }

    .logo {
      width: @bei*124px;
      height: @bei*66px;
    }

    .bc {
      text-align: right;
      color: #000;
      margin: @bei*10px 0 @bei*40px;

      .t1 {
        font-size: @bei*22px;
        font-weight: bold;
        margin-bottom: @bei*10px;
      }

      .t2 {
        font-size: @bei*18px;
      }
    }

    .ucode {
      width: @bei*200px;
      height: @bei*200px;
      overflow: hidden;
    }
  }
}
.course-list {
  width: 100%;
  .course-item {
    background: #f5f5f5;
    border-radius: 12px;
    padding: 24px 28px 24px 24px;
    &:not(:last-child) {
      margin: 0 0 20px;
    }
    .c-poster {
      width: 156px;
      height: 156px;
      box-shadow: 0px 2 9px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/default-banner.png);
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;
      .index {
        position: absolute;
        top: 16px;
        left: 16px;
        color: #fff;
      }
      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: linear-gradient(221deg, #90f52b 0%, #cbfc40 100%);
      }
    }
    .c-content {
      margin: 0 26px 0;
      overflow: hidden;
      .line-1 {
        margin: 24px 0;
      }
      .p1 {
        margin: 0 24px 0 0;
      }
      .p2 {
        margin: 0 12px 0 0;
      }
    }
  }
}
.opacity9 {
  opacity: 0.9;
}
.color-red {
  color: #f01818;
}
.color-blue {
  color: #267dff;
}
/deep/.canvas-box {
  padding-left: 40px !important;
  padding-right: 40px !important;
  box-sizing: border-box;

  .create-img {
    width: 100% !important;
  }
}
</style>
