<template>
  <div>
    <nav-bar :headerTitle="applicationTrends == '2' ? '日常情况评估' : '风险评估'" ref="head" :show-back="true" :header-back="newAppBack" />

    <!-- 提交成功 -->
    <div v-if="isSuccess" class="container success col-start-center">
      <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/myBusinessManagement/ok.png" alt="">
      <p class="s1 f36 fw6">提交成功</p>
      <p class="s2 f26">感觉您的耐心填写，教练将根据您的身体情况<br>制作课程，期待和您在超鹿的相遇。</p>

      <div class="button-box success f32 row-between-center">
        <div @click="reFill" :class="['button', 'plain', 'row-center-center']">重新填写</div>
        <div @click="look" :class="['button', 'row-center-center', 'fw6']">查看详情</div>
      </div>
    </div>

    <template v-else>
      <!-- 未填写 -->
      <div v-if="isStartFill" class="container col-start-center">
        <div v-if="isFillin" class="create-time f24 row-center-center">本次问卷于 {{ detail.createDate }} 创建</div>

        <!-- 只有风险评估有 -->
        <div class="block-item" v-if="detail.result">
          <h2 class="title f32 fw6">评估结果</h2>
          <div class="question-item">
            <div class="row-start-center">
              <div class="tag row-center-center fw6" :class="[detail.result.riskFactor === 0 ? 'low' : detail.result.riskFactor === 1 ? 'mid' : 'high']">{{ detail.result.riskFactorName }}</div>
            </div>
            <div class="show-box">
              <p class="f28">{{ detail.result.notesData }}</p>
            </div>
          </div>
        </div>

        <div class="block-item" v-for="item in questionnaireBagList" :key="item.bagId">
          <h2 class="title f32 fw6">{{ item.bagName }}</h2>
          <div class="question-item" v-for="question, index in item.questionResultList" :key="question.questionId" :id="`q${question.questionId}`">
            <div class="row-start-center">
              <span class="f28 fw6">{{ index + 1 }}. {{ question.questionTitle }}</span>
              <span v-if="!isFillin && question.type === 2" class="multi f20 fw6 row-center-center flex-none">可多选</span>
              <!-- <span v-if="!isFillin && question.type !== 3 && question.required == 1" class="must f24 fw6">（请至少选择一项）</span> -->
              <span v-if="!isFillin && question.required == 1" class="must f24 fw6 flex-none">（必填）</span>
            </div>

            <div v-if="isFillin" class="show-box">
              <p class="f28">{{ question.answerStr }}</p>
            </div>
            <div v-else class="select-box row-between-center flex-wrap f24">
              <template v-if="question.type === 1">
                <div @click="chooseItem(question.questionId, answer.answerId)" v-for="answer in question.answerList" :key="answer.answerId"
                  :class="['select-item', 'row-center-center', questionnaireInfo[whichQuestion(question.questionId)].answerIds.includes(answer.answerId) ? 'active fw6' : '']">{{ answer.answer }}</div>
              </template>
              <template v-if="question.type === 2">
                <div @click="chooseMoreItem(question.questionId, answer.answerId)" v-for="answer in question.answerList" :key="answer.answerId"
                  :class="['select-item', 'row-center-center', questionnaireInfo[whichQuestion(question.questionId)].answerIds.includes(answer.answerId) ? 'active fw6' : '']">{{ answer.answer }}</div>
              </template>
              <template v-if="question.type === 3">
                <van-field class="text-area" v-model="questionnaireInfo[whichQuestion(question.questionId)].fillAnswer" type="textarea" placeholder="请在此输入回答内容" autosize />
              </template>
              <div class="select-item" style="opacity: 0; min-height: 0; padding: 0;"></div>
            </div>

            <div :class="['mask', mustId === question.questionId ? 'ani' : '']"></div>
          </div>

        </div>
      </div>
      <div v-if="isStartFill" class="button-box f32">
        <div @click="confirm" :class="['button', 'row-center-center', 'fw6', disabled ? 'disabled' : '']">{{ buttonText }}</div>
      </div>
      <!-- 空状态 -->
      <div v-if="!isStartFill" class="container col-center-center">
        <div class="empty col-center-center">
          <img src="https://img.chaolu.com.cn/ACT/mini-records/empty.png" alt="">
          <p class="f28">您未填写过{{ applicationTrends == 1 ? '风险评估' : '日常评估' }}！<br>为了让教练更好了解您的身体情况，请您<br>花1-2分钟完成日常情况评估</p>
        </div>
      </div>
      <div v-if="!isStartFill" class="button-box f32">
        <div @click="startFill" :class="['button', 'row-center-center', 'fw6', disabled ? 'disabled' : '']">{{ buttonText }}</div>
      </div>
    </template>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack, webAppShare } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import wx from 'weixin-js-sdk'

export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {

      shareParams: {
        title: '日常情况评估',
        image: 'https://img.chaolu.com.cn/MINI/minicard/talk-order-1.png',
        path: ``,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },

      disabled: true,

      detail: {},
      questionnaireBagList: [], // 问题list
      isFillin: false, // 是否填写
      isStartFill: false, // 开始回答
      isSuccess: false, // 提交成功

      applicationTrends: '',
      questionnaireInfo: [], // 提交的表单
      mustId: '', // 未选中或为填写的 第一个id 提示高亮
    }
  },
  computed: {
    buttonText() {
      if (this.teacherId) return '邀请用户填写问卷'
      return this.isFillin ? '重新评估' : this.isStartFill ? '提交' : '开始评估'

    }
  },
  async created() {
    // 有teacherId 表示当前页面在教练端 底部按钮变成 ‘邀请用户填写问卷’ 直接拉起微信好友列表分享小程序卡片
    await this.$getAllInfo(['userId', 'teacherId'])
    initBack()

    // 教练端 教练看学员的
    this.studentId = getParam().studentId
    // 风险评估1   日常情况2
    this.applicationTrends = getParam().applicationTrends || '2'
    this.shareParams.title = this.applicationTrends === '1' ? '风险评估' : '日常情况评估'
    this.shareParams.image = `https://img.chaolu.com.cn/MINI/minicard/talk-order-${this.applicationTrends === '1' ? '2' : '1'}.png`
    this.shareParams.path = `/pages/webView/index?webUrl=${window.location.origin}/#/talk-order/questionnaire&applicationTrends=${this.applicationTrends}&userId=1`
    document.title = this.applicationTrends === '1' ? '风险评估' : '日常情况评估'

    this.getDetail()

    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.image,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
  },

  methods: {
    newAppBack,
    getDetail(isReassessment = false) {
      this.$toast.loading({
        duration: 600,
        forbidClick: true,
        overlay: true,
        loadingType: 'spinner'
      })
      window.scrollTo({
        top: 0,
      })

      // 教练端 换个接口
      const interFace = this.teacherId ? 'coach/userQuestionnaire/getUserQuestionnaireByTrends' : 'userQuestionnaire/getUserQuestionnaireByTrends'

      this.$axios.post(`${this.baseURLApp}/${interFace}`, {
        userId: this.userId || this.studentId,
        token: this.token,
        applicationTrends: this.applicationTrends,
        isReassessment: isReassessment
      }).then(res => {
        const r = res.data
        this.detail = r
        this.questionnaireBagList = r.questionnaireBagList

        this.isStartFill = r.userQuestionnaireStatus === 1 || this.isFillin
        this.isFillin = r.userQuestionnaireStatus === 1

        this.$toast.clear()
        this.resetForm()
      }).catch(r => {

      })
    },

    whichQuestion(id) {
      let i = 0
      this.questionnaireInfo.map((item, index) => {
        if (item.questionId === id) i = index
      })
      return i
    },
    resetForm() {
      this.questionnaireInfo = []
      this.questionnaireBagList.map((bag) => {
        bag.questionResultList.map((question) => {
          this.questionnaireInfo.push({
            answerIds: [],
            fillAnswer: '',
            required: question.required,
            questionId: question.questionId
          })
        })
      })
    },

    chooseItem(questionId, answerId) {
      let i = this.whichQuestion(questionId)
      console.log(i, 'jjj');
      if (answerId === this.questionnaireInfo[i].answerIds[0]) {
        this.questionnaireInfo[i].answerIds = []
      } else {
        this.questionnaireInfo[i].answerIds = [answerId]
      }
    },
    chooseMoreItem(questionId, answerId) {
      let i = this.whichQuestion(questionId)
      let list = this.questionnaireInfo[i].answerIds
      const answerIndex = list.indexOf(answerId)
      list.includes(answerId) ? list.splice(answerIndex, 1) : list.push(answerId)
    },
    startFill() {
      if (this.teacherId) {
        webAppShare('3', this.shareParams)
        return
      }
      this.$toast.loading({
        duration: 600,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner"
      })
      this.isStartFill = true
    },
    confirm() {
      if (this.teacherId) {
        webAppShare('3', this.shareParams)
        return
      }
      // 已填写过 点击去重填
      if (this.isFillin) {
        this.getDetail(true)
        return
      }

      // 检测表单 必填项
      let isOk = this.checkForm()
      if (!isOk) {
        this.$toast('必填项未选择或未填写')
        return
      }

      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: '加载中...',
      })
      this.$axios.post(`${this.baseURLApp}/userQuestionnaire/submit`, {
        questionnaireId: this.detail.questionnaireId,
        questionnaireInfo: this.questionnaireInfo,
        userId: this.userId,
        token: this.token
      }).then(res => {
        window.scrollTo({
          top: 0,
        })
        this.$toast.clear()
        this.isSuccess = true
        this.isFillin = true
      }).catch(() => {

      })
    },
    checkForm() {
      let isOk = true
      for (let i = 0; i < this.questionnaireInfo.length; i++) {
        let item = this.questionnaireInfo[i]
        if (item.required && !item.answerIds.length && !item.fillAnswer) {
          isOk = false

          this.mustId = item.questionId
          let el = document.getElementById(`q${item.questionId}`)
          this.$nextTick(() => {
            window.scrollTo({
              behavior: 'smooth',
              top: el.offsetTop - 100,
            })
          })
          setTimeout(() => {
            this.mustId = ''
          }, 2000)
          break;
        }
      }
      return isOk
    },

    look() {
      this.$toast.loading({
        duration: 800,
        forbidClick: true,
        loadingType: 'spinner'
      })
      this.getDetail()
      this.isSuccess = false
    },
    reFill() {
      this.getDetail(true)
      this.isSuccess = false
    }
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.container {
  box-sizing: border-box;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  width: 100%;
  padding: 2px 0 calc(30vh + env(safe-area-inset-bottom));

  .create-time {
    width: 100%;
    background-color: #f5f5f5;
    color: #6C727A;
    height: 72px;
  }

  .empty {
    text-align: center;
    margin: -200px 0 0;

    img {
      width: 300px;
    }

    p {
      color: #6C727A;
      margin: 24px 0 0;
      line-height: 44px;
    }
  }

  .block-item {
    width: 100%;
    margin: 0 0 24px;
    background-color: #fff;
    padding: 40px 32px 16px;
    border-radius: 8px;

    .tag {
      width: fit-content;
      padding: 0 23px;
      height: 56px;
      background: rgba(245, 245, 245, 1);
      border-radius: 8px;
      margin: 0 0 16px;
      &.low {
        background: rgba(38, 125, 255, 0.10);
        color: rgba(38, 125, 255, 1);
      }
      &.mid {
        background: rgba(255, 110, 0, 0.10);
        color: rgba(255, 110, 0, 1);
      }
      &.high {
        background: rgba(252, 44, 17, .1);
        color: #FC2C11;
      }
    }

    .title {
      line-height: 1;
      padding: 0 0 0 18px;
      border-left: 10px solid #FFDE00;
      margin: 0 0 40px;
    }

    .question-item {
      margin: 0 0 40px;
      position: relative;

      &:last-child {
        margin: 0 0 0;
      }

      .mask {
        width: 105%;
        height: 105%;
        position: absolute;
        left: -2.5%;
        top: -2.5%;
        z-index: -1;
        opacity: 0;
        background-color: rgba(0, 0, 0, .3);

        &.ani {
          animation: flash 2s linear alternate;
          z-index: 1;
        }
      }
    }

    @keyframes flash {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      75% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .multi {
      padding: 0 21px;
      height: 40px;
      background: #F5F5F5;
      border-radius: 20px;
      color: #6C727A;
      margin: 0 0 0 16px;
    }

    .must {
      color: #ED5C42;
      margin: 0 0 0 16px;
    }

    .show-box {
      margin: 12px 0 0;
      line-height: 44px;
    }

    .select-box {
      padding: 24px 0 0;
      margin: 16px 0 0;

      .text-area {
        background-color: #F5F5F5;
        border-radius: 8px;

        &::placeholder {
          color: #6C727A;
        }
      }

      .select-item {
        width: 208px;
        min-height: 68px;
        padding: 12px;
        margin: 0 0 24px;
        border-radius: 8px 8px 8px 8px;
        background-color: #F5F5F5;
        border: 2px solid #F5F5F5;

        &.active {
          border: 2px solid #232831;
        }
      }
    }
  }
}

.button-box {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  color: #242831;
  position: sticky;
  bottom: 0;
  padding: 20px 32px calc(20px + env(safe-area-inset-bottom));
  border-top: 1px solid #EBEBEB;
  position: sticky;
  bottom: 0;
  left: 0;

  .button {
    width: 100%;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;

    // &.disabled {
    //   opacity: .6;
    // }
  }

}

.container.success {
  background-color: #fff;
  padding: 250px 0 0;
  text-align: center;

  img {
    width: 128px;
  }

  .s1 {
    margin: 32px 0 42px;
  }

  .s2 {
    color: #6C727A;
    margin: 0 0 70px;
    line-height: 44px;
  }

  .button-box {
    position: relative;
    bottom: none;
    left: none;
    border-top: none;

    .button {
      width: 333px;
    }

    .plain {
      background-color: transparent;
      border: 2px solid #EEEEEE;
    }
  }
}</style>
